import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Loader, Footer, Header, ErrorPage } from "@gogeepernpm/storybook/lib";
//@ts-ignore
import AOS from "aos";
import { useSelector } from "react-redux";
import { IRootReducerState } from "./redux/IRootReducer";
import { useGlobalAuth } from "./hooks/useGlobalAuth";
import "./styles/main.scss";
import Unreachable from "./Components/Unreachable";
import { authBaseUrl } from "./provider/auth.provider";
import useAuth from "./hooks/useAuth";
import {toast} from 'react-toastify';

const Homepage = lazy(() => import("./pages"));

function App() {
  const newAuth = useAuth();
  const { isInitialized } = useSelector(
    (state: IRootReducerState) => state.auth
  );

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleItemClick = (index: number) =>
    activeIndex === index ? setActiveIndex(-1) : setActiveIndex(index);
  const toggleSidebar = () => setOpen(!open);

  const contact = () => window.Tawk_API.maximize();
  // useEffect(() => {}, [isInitialized]);

  const { triggerLogout, triggerSignUp, triggerlogin } = useGlobalAuth();

  const { isAuthenticated } = useSelector(
    (state: IRootReducerState) => state.auth
  );
  const [unreachable] = useState(true);


  useEffect(() => {
    function receiveMessage(event: MessageEvent) {

      if (event.origin !== authBaseUrl.replace(/\/oauth\/authorize$/, '')) return; // replace with the actual origin of the Auth App
      const { accessToken } = event.data;
      if (accessToken) {
        newAuth?.setAuth({ accessToken }); // Store the accessToken in the main app's state
        // simulate the login when the user is logged in
        setTimeout(() => {
          toast.success('Login successful! Welcome back!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          });
        }, 1000);
      }
    }

    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [newAuth?.auth]);



  return (
    <div className="App">
      {unreachable ? (
        <>
          <Unreachable/>
        </>
      ) : (
        <>
          <Suspense fallback={<Loader loading={true} />}>
            <Header
              sidebarOpen={open}
              toggleSidebarOpen={toggleSidebar}
              onTalkToUsClick={contact}
              activeIndex={activeIndex}
              onClick={handleItemClick}
              isLoggedIn={isAuthenticated}
              triggerLogin={triggerlogin}
              triggerSignup={triggerSignUp}
              triggerLogout={triggerLogout}
            />

            <Router>
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route
                  path="*"
                  element={
                    <ErrorPage onContact={contact} onSignUp={triggerSignUp} />
                  }
                />
              </Routes>
            </Router>
            <Footer onTalkToUsClick={contact} />
          </Suspense>
        </>
      )}
    </div>
  );
}

export default App;
